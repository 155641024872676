import { createStore } from 'vuex'

import TreeModule from './features/tree/module/TreeModule'
import FormModule from './features/wish/module/FormModule'

export default createStore({
    state: {},
    modules: {
        tree: TreeModule,
        form: FormModule
    }
});