import Wish from '../../../models/Wish';
import { collection } from '../../../providers/SnapFirestore';
import firebase from 'firebase/app';

const WISH_STATUS_WAITING = 'waiting';
const WISH_STATUS_PUBLISHED = 'published';
// const WISH_STATUS_UNPUBLISHED = 'unpublished';

export const service = {
  async getWish(id) {
    let snapshot = await collection.doc(id).get()
    let data = snapshot.data();
    if (data) {
      return new Wish({
        id: id,
        lastname: data.lastname,
        firstname: data.firstname,
        email: data.email,
        message: data.message
      });
    }
  },
  async getWishesByEmail(email) {
    let snapshot = await collection.where("email", "==", email).get();

    if (snapshot) {
      return snapshot.docs.map(doc => {
        let data = doc.data();
        return new Wish({
          id: doc.id,
          lastname: data.lastname,
          firstname: data.firstname,
          email: data.email,
          message: data.message
        });
      });
    }
  },
  async getWishes() {
    let published = await collection.where("status", "==", WISH_STATUS_PUBLISHED).get();

    let dt = new Date(Date.now());
    dt.setDate(dt.getDate() - 1);
    let waiting = await collection.where("status", "==", WISH_STATUS_WAITING).where("createdAt", ">=", dt).get();

    let ws = [...published.docs, ...waiting.docs].map(doc => {
      let data = doc.data();
      return new Wish({
        id: doc.id,
        lastname: data.lastname,
        firstname: data.firstname,
        email: data.email,
        message: data.message
      });
    });

    return ws;

  },
  async postWish(wish) {
    try {
      let doc = await collection.add({
        lastname: wish.lastname,
        firstname: wish.firstname,
        email: wish.email,
        message: wish.message,
        commercialOptin: wish.commercialOptin,
        status: WISH_STATUS_WAITING,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      });

      return new Wish({
        id: doc.id,
        lastname: wish.lastname,
        firstname: wish.firstname,
        email: wish.email,
        message: wish.message,
        commercialOptin: wish.commercialOptin,
        status: wish.status,
        createdAt: wish.createdAt
      });
    } catch (e) {
      console.log('post wish error', e);
      return Error(e);
    }
  }
}