<template>
  <div>
    <button
      class="clipboard-field"
      v-bind:class="{ clipboarded: clipboarded }"
      @click="copy"
    >
      <i class="fas fa-copy" v-if="!clipboarded"></i>
      <i class="fas fa-check" v-else></i>
      <span v-if="!clipboarded">Copier pour partager</span>
      <span v-else>Copié</span>
    </button>
  </div>
</template>
<script>
export default {
  name: "ClipboardField",
  props: ["url"],
  data() {
    return { clipboarded: false };
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.url).then(async () => {
        this.clipboarded = (await navigator.clipboard.readText()) == this.url;
      });
    },
  },
};
</script>
<style scoped>
.clipboard-field {
  text-align: center;
  vertical-align: middle;
  color: #888;
  background-color: #eee;
  border: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  cursor: pointer;
  margin-right: 0.4rem;
}

@media (max-width: 600px) {
  .clipboard-field {
    font-size: calc(0.2rem + 1vw);
  }
}

.clipboard-field > i {
  color: white;
  background-color: lightsalmon;
}

.clipboard-field.clipboarded > i {
  background-color: lightseagreen;
}

.clipboard-field > * {
  padding: 0.6rem;
}

@media (max-width: 800px) {
  .clipboard-field > span {
    display: none;
  }
}
</style>