import { createApp } from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import Home from './features/home/view/Home.vue'
import store from './store'
import { firestorePlugin } from 'vuefire'
import mitt from 'mitt';
import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    {
        path: '/:slug', // (^[0-9a-zA-Z]{20})',
        component: Home
    },
    {
        path: '/ajout',
        component: Home
    },
    {
        path: '/:pathMatch(.*)*',
        component: Home
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

createApp(App)
    .use(router)
    .use(store)
    .use(Vuex)
    .use(store)
    .use(firestorePlugin)
    .provide('mitt', mitt())
    .mount('#app');
