<template>
  <div class="social-share-list">
    <button
      class="social-network"
      v-for="network in networks"
      :key="network"
      v-bind:style="{ 'background-color': network.color }"
      @click="share(network.network)"
    >
      <i v-bind:class="network.icon"></i>
      <span>{{ network.name }}</span>
    </button>
  </div>
</template>
<script>
const objectToGetParams = function (object) {
  return (
    "?" +
    Object.keys(object)
      .filter((key) => !!object[key])
      .map((key) => `${key}=${encodeURIComponent(object[key])}`)
      .join("&")
  );
};

export default {
  name: "SocialShare",
  props: ["url", "title"],
  data() {
    return {
      networks: [
        {
          network: "facebook",
          name: "Facebook",
          icon: "fab fah fa-lg fa-facebook-f",
          color: "#1877f2",
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "fab fah fa-lg fa-twitter",
          color: "#1da1f2",
        },
      ],
    };
  },
  methods: {
    share(network) {
      switch (network) {
        case "facebook":
          this.facebook(this.url);
          break;
        case "twitter":
          this.twitter(this.url, { title: this.title });
          break;
      }
    },
    facebook(url) {
      window.open(
        `https://www.facebook.com/sharer/sharer.php` +
          objectToGetParams({ u: url }),
        "__blank"
      );
    },
    twitter(url, { title }) {
      window.open(
        "https://www.twitter.com/share" +
          objectToGetParams({ url: url, text: title }),
        "__blank"
      );
    },
  },
};

// https://nicolasbeauvais.github.io/vue-social-sharing/?path=/story/vuesocialsharing--multiple-share-networks
// https://github.com/mbj36/vue-socialmedia-share/blob/master/src/components
</script>
<style scoped>
.social-network {
  text-align: center;
  vertical-align: middle;
  color: white;
  border: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  cursor: pointer;
  margin-right: 0.4rem;
}

@media (max-width: 600px) {
  .social-network {
    font-size: calc(0.2rem + 1vw);
  }
}

.social-network > i {
  background-color: rgba(0, 0, 0, 0.2);
}

.social-network > * {
  padding: 0.6rem;
}

@media (max-width: 800px) {
  .social-network > span {
    display: none;
  }
}
</style>