<template>
  <aside>
    <ul>
      <li v-for="value in socials" :key="value.url">
        <a :href="value.url" target="_blank">
          <img :src="value.src" />
        </a>
      </li>
    </ul>
  </aside>
</template>
<script>
export default {
  name: "Social",
  data() {
    return {
      socials: [
        {
          url: "https://www.facebook.com/SNAPQuebec",
          src: "./imgs/facebook-white.png",
        },
        {
          url: "https://twitter.com/snapqc",
          src: "./imgs/twitter-white.png",
        },
        {
          url: "https://www.instagram.com/snapquebec/",
          src: "./imgs/instagram-white.png",
        },
      ],
    };
  },
};
</script>
<style scoped>
aside {
  width: 2rem;

  position: fixed;
  bottom: 1rem;
  right: 1rem;

  display: flex;
  flex-direction: column;
}

li {
  list-style: none;
  margin-top: 1rem;
}

li > a > img {
  width: 2rem;
  max-height: 2rem;
}
</style>