<template>
  <i
    :class="['fas', 'fa-' + icon, disabled ? 'disabled' : null]"
    v-on:click.self="clicked"
  />
</template>
<script>
export default {
  name: "SolidButton",
  props: ["icon", "disabled", "onclick"],
  methods: {
    clicked() {
      if (!this.disabled) this.onclick();
    },
  },
};
</script>
<style scoped>
i {
  width: 5vmin;
  height: 5vmin;
  border-radius: 10vmin;
  border: 0px;
  background-color: #ddc7a8;
  color: white;
  font-size: 2vmin;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: background 200ms;
}

i:hover {
  background-color: #ccb697;
}

i:active {
  background-color: #bba586;
}

i.disabled {
  background-color: #a5a5a5 !important;
  opacity: 0.33;
}
</style>