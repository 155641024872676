<template>
  <div class="la-form">
    <p>
      Merci pour vos contributions.
      <br />
      <br />
      Vous avez dépassé votre nombre de souhaits maximal.
    </p>
  </div>
</template>
<script>
export default {
  name: "LimitAccessForm",
};
</script>
<style scoped>
.la-form {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: monospace, sans-serif;
  font-size: 3vmin;
}
</style>