<template>
  <div id="snap">
    <Header />
    <AddWishButton
      class="add-btn"
      v-on:click="addWish"
      v-bind:class="openAdd ? 'opened' : null"
    />
    <Tree />
    <div class="space"></div>
  </div>

  <Social />
  <Dialog v-if="openWish">
    <PaperBackground>
      <ReadWishPaper :wish="wishData" />
    </PaperBackground>
  </Dialog>
  <Dialog v-else-if="openAdd">
    <PaperBackground>
      <WishPanel />
    </PaperBackground>
  </Dialog>
</template>
<script>
import Tree from "../../tree/component/Tree.vue";
import Social from "../component/Social.vue";
import Header from "../component/Header.vue";
import PaperBackground from "../component/PaperBackground.vue";
import ReadWishPaper from "../component/ReadWishPaper.vue";
import Dialog from "../../../components/Dialog.vue";
import WishPanel from "../../wish/component/WishPanel.vue";
import AddWishButton from "../component/AddWishButton.vue";

export default {
  name: "Home",
  inject: ["mitt"],
  components: {
    AddWishButton,
    Dialog,
    Header,
    Tree,
    Social,
    PaperBackground,
    ReadWishPaper,
    WishPanel,
  },
  data() {
    return {
      wishPaperSVG: require("../../../assets/img_0.svg"),
      openWish: false,
      openAdd: false,
    };
  },
  created() {
    this.mitt.on("oWish", this.oWish);
    this.mitt.on("cWish", this.cWish);
    this.mitt.on("oAdd", this.oAdd);
    this.mitt.on("cAdd", this.cAdd);

    this.$watch(
      () => this.$route,
      (current) => {
        if (current.path == "/ajout") {
          this.mitt.emit("oAdd");
        } else if (current.params?.slug && current.params?.slug.length == 20) {
          let id = current.params?.slug;
          this.$store.commit("tree/wishById", id);
          this.mitt.emit("oWish");
        } else {
          // if (typeof current.params?.slug == 'undefined') {
          this.mitt.emit("cWish");
          this.mitt.emit("cAdd");
        }
      }
    );
  },
  mounted() {
    // on launch
    let route = this.$router.currentRoute.value;
    if (route.path == "/ajout") {
      this.mitt.emit("oAdd");
    } else if (route.params?.slug && route.params?.slug.length == 20) {
      let id = route.params?.slug;
      this.$store.commit("tree/wishById", id);
      this.mitt.emit("oWish");
    } else if (typeof route.params?.slug == "undefined") {
      this.mitt.emit("cWish");
      this.mitt.emit("cAdd");
    }
  },
  computed: {
    wishData() {
      return this.$store.state.tree.wish;
    },
  },
  methods: {
    refreshWish() {
      this.$store.commit("tree/getRandom");
      this.$router.push({ path: "/" + this.$store.state.tree.wish.id });
    },
    addWish() {
      this.$router.push({ path: "/ajout" });
    },
    oWish() {
      this.openWish = true;
    },
    cWish() {
      this.openWish = false;
    },
    oAdd() {
      this.openAdd = true;
    },
    cAdd() {
      this.openAdd = false;
      this.$store.commit("form/finish");
    },
  },
};
</script>

<style scoped>
.opened {
  opacity: 0;
}

.add-btn {
  z-index: 1;
  flex: 1;
  width: 30%;
  max-width: 18rem;

  transition: transform 300ms;

  margin-bottom: 1vmin;
}

.add-btn:hover {
  transform: scale(1.1);
}

#snap,
#snap::before {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#snap {
  overflow: hidden;
  background-image: url("/imgs/tree_bg.webp");
  background-attachment: fixed;
  background-position: bottom;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
}

#snap::before {
  content: "";
  background-image: url("/imgs/floor_0.webp");
  background-attachment: fixed;
  background-position: bottom;
  position: absolute;
}

.space {
  width: 100vw;
  height: 18vh;

  flex-shrink: 0;
}
</style>