<template>
  <div class="read-wish-paper">
    <div class="read-paper-body">
      <p
        :style="{
          'font-size':
            'calc(' + dynamicFontSize + 'vw + ' + dynamicFontSize + 'vh)',
        }"
      >
        {{ wish?.message }}
      </p>
    </div>
    <div class="read-paper-footer">
      <div class="read-paper-share">
        <SocialShare
          :url="shareLink"
          :title="'Parce que je veux protéger la nature québécoise, j’ai ajouté mon souhait.\nToi aussi participe à cette nouvelle tradition !'"
        />
        <clipboard-field :url="shareLink" />
      </div>
      <p>{{ wish?.firstname }} {{ wish?.lastname[0] }}.</p>
    </div>
  </div>
</template>
<script>
import ClipboardField from "../../../components/ClipboardField.vue";
import SocialShare from "../../../components/SocialShare.vue";

export default {
  name: "ReadWishPaper",
  props: ["wish"],
  components: {
    SocialShare,
    ClipboardField,
  },
  computed: {
    dynamicFontSize() {
      let len = this.wish?.message.length ?? 200;
      let ave = len / 200;
      return 1.2 * (1 - ave) + 1;
    },
    shareLink() {
      return process.env.VUE_APP_BASE_URL + "/#/" + this.wish?.id;
    },
  },
};
</script>
<style scoped>
.read-wish-paper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.read-paper-body {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.read-paper-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

p {
  font-family: Satisfy;
}

.read-paper-footer p {
  font-size: 3vmin;
}

.read-paper-share {
  display: flex;
}
</style>