<template>
  <div id="tree-container">
    <div id="tree">
      <img src="/imgs/tree_body.webp" />
      <WishPaper
        v-for="key in papers"
        v-bind:key="key"
        v-on:click="refreshWish"
        :nbr="key"
        :tot="papers"
      />
    </div>
  </div>
</template>
<script>
import WishPaper from "./WishPaper.vue";

export default {
  name: "Tree",
  inject: ["mitt"],
  components: {
    WishPaper,
  },
  data() {
    return {
      papers: 50,
    };
  },

  methods: {
    refreshWish() {
      this.$store.commit("tree/getRandom");
      this.$router.push({ path: "/" + this.$store.state.tree.wish.id });
    },
  },
};
</script>
<style scoped>
#tree-container {
  display: flex;
  align-items: flex-end;
}

#tree {
  transform: translateY(0%);
  position: relative;
}

#tree img {
  max-width: 100%;
  height: auto;
  max-height: 70vh;

  -o-object-fit: contain;
  object-fit: contain;
}

@media (max-aspect-ratio: 10/8) {
  #tree {
    transform: translateY(13%);
  }

  #tree img {
    max-height: 60vh;
  }
}

#tree::after,
#tree::before {
  content: "";

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  animation-iteration-count: infinite;
  animation-duration: 4s;

  will-change: transform;

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;

  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
}

@keyframes leafs-moving {
  0% {
    transform: translate3d(0.09%, 0, 0);
  }
  50% {
    transform: translate3d(-0.09%, 0, 0);
  }
  100% {
    transform: translate3d(0.09%, 0, 0);
  }
}

@keyframes leafs-moving-opposite {
  0% {
    transform: translate3d(-0.08%, 0, 0);
  }
  50% {
    transform: translate3d(0.08%, 0, 0);
  }
  100% {
    transform: translate3d(-0.08%, 0, 0);
  }
}

#tree::before {
  background-image: url("/imgs/tree_leafs_2.webp");
  animation-name: leafs-moving-opposite;
}

#tree::after {
  background-image: url("/imgs/tree_leafs_1.webp");
  animation-name: leafs-moving;
}
</style>