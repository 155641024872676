export default class Wish {
    constructor(wish) {
        this.id = wish.id;
        this.lastname = wish.lastname;
        this.firstname = wish.firstname;
        this.email = wish.email;
        this.message = wish.message;
        this.commercialOptin = wish.commercialOptin;
        this.status = wish.status;
        this.createdAt = wish.createdAt;
    }
}