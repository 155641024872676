import { service } from '../service/TreeService'

export default {
    namespaced: true,
    state: {
        wish: null,
        wishes: [],
    },
    mutations: {
        async load(state) {
            state.wishes = await service.getWishes();
            if (!state.wish) {
                state.wish = state.wishes[0];
            }
        },
        getRandom(state) {
            let length = state.wishes.length;
            let index = Math.floor(Math.random() * length);
            state.wish = state.wishes[index];
        },
        async wishById(state, id) {
            state.wish = await service.getWish(id);
        },
        async save(state, input) {
            let e = await service.postWish(input);

            if (e instanceof Error) {
                console.log('save :', e);
            } else {
                state.wish = e;
                state.wishes = await service.getWishes();
            }
        }
    },
};