<template>
  <div class="wish-container">
    <div class="wish-container-body">
      <i class="fas fa-feather-alt"></i>
      <span v-for="(n, index) in numbers" :key="index">{{ n }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "WishCounter",
  props: ["counter"],
  computed: {
    numbers() {
      let ar = [...this.counter.toString()];
      for (let i = ar.length; i < 5; i++) {
        ar.unshift("0");
      }

      return ar;
    },
  },
};
</script>
<style scoped>
div {
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 3px 3px #0006);
}

div.wish-container-body {
  font-size: 2vmin;
  background-color: var(--paper-dark);
  padding: 1vmin;
  border-radius: 0.5vmin;
}

i {
  margin-right: 1vmin;
}

span {
  box-sizing: border-box;
  padding: 0.5vmin;
  background-color: black;
  margin-right: 0.5vmin;
  border-radius: 0.25vmin;
}

span:last-child {
  margin-right: 0;
}
</style>