<template>
  <div class="w-viewport">
    <LimitAccessForm v-if="formData == -2" />
    <NameForm v-else-if="formData == 0" />
    <MessageForm v-else-if="formData == 1" />
    <InfoForm v-else-if="formData == 2" />
    <EndForm v-else-if="formData == 3" />
  </div>
</template>
<script>
import EndForm from "./EndForm.vue";
import InfoForm from "./InfoForm.vue";
import LimitAccessForm from "./LimitAccessForm.vue";
import MessageForm from "./MessageForm.vue";
import NameForm from "./NameForm.vue";

export default {
  name: "WishPanel",
  components: {
    EndForm,
    InfoForm,
    LimitAccessForm,
    MessageForm,
    NameForm,
  },
  computed: {
    formData() {
      let data = this.$store.state.form;

      if (data.limitAccess) {
        return -2;
      } else if (!data.lastname && !data.firstname) {
        return 0;
      } else if (!data.message) {
        return 1;
      } else if (!data.email) {
        return 2;
      }

      return 3;
    },
  },
};
</script>
<style scoped>
.w-viewport {
  height: 100%;
  display: flex;
}
</style>