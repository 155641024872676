<template>
  <div class="wish-add-container">
    <div class="wish-add">
      <img src="imgs/img_0.png" />
      <p>Ajoutez votre souhait</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "AddWishButton",
};
</script>
<style scoped>
.wish-add-container {
  display: flex;
  align-items: center;
}

.wish-add {
  transition: transform 300ms;
  cursor: pointer;
  position: relative;
}

.wish-add img {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  filter: drop-shadow(0px 3px 7px #0009);
}

.wish-add p {
  padding: 1vmin;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 30%;
  box-sizing: border-box;
  font-size: calc(0.6rem + 0.5vmin);
  font-family: monospace, sans-serif;
}
</style>