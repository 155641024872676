<template>
  <div class="wish-panel">
    <img :src="wishPaperSVG" />
    <div class="wish-panel-content">
      <div class="wish-panel-close">
        <i class="fas fa-times" v-on:click.self="close" />
      </div>
      <div class="wish-panel-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PaperBackground",
  data() {
    return {
      wishPaperSVG: require("../../../assets/img_0.svg"),
    };
  },
  methods: {
    close() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
<style>
@keyframes pop {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wish-panel {
  position: absolute;
  top: 0;
  transform: translate(calc(50vw - 50%), calc(50vh - 50%));
  width: calc(100% - 10vw);
  max-width: 70rem;
  max-height: 80vh;

  animation-duration: 300ms;
  animation-name: pop;
}

.wish-panel-content {
  top: 0;
  right: 0;
  position: absolute;
  width: 75%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.wish-panel-close {
  display: flex;
  justify-content: flex-end;
}

.wish-panel-close i {
  padding: 2vmin;
  cursor: pointer;
  font-size: 2vmin;
  color: var(--paper-dark);
}

.wish-panel-body {
  flex: 1;
  padding: 0 1rem 1rem 1rem;
  overflow: hidden;
}
</style>