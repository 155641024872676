<template>
  <div class="header-snap">
    <header>
      <div class="header-snap-title">
        <h1>Arbre à souhaits</h1>
        <span>Dites-nous pourquoi vous voulez protéger la nature </span>
      </div>
      <div class="header-snap-counter">
        <WishCounter :counter="wishesCount" />
      </div>
      <div class="header-snap-logo">
        <a href="https://snapquebec.org/" target="_blank">
          <img :src="'./imgs/snap-quebec-logo.png'" />
        </a>
      </div>
    </header>
    <nav class="header-nav">
      <ul>
        <li v-for="value in links" :key="value.url">
          <a :href="value.url" target="_blank">
            <span>{{ value.text }}</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
import WishCounter from "./WishCounter.vue";

export default {
  name: "Header",
  components: {
    WishCounter,
  },
  data() {
    return {
      links: [
        {
          url: "https://amplify.e-activist.com/19981/galn",
          text: "Grâce à la Nature",
        },
        // {
        //   url: "https://donate.cpaws.org/page/29172/donate/1",
        //   text: "Faire un don",
        // },
        // {
        //   url: "https://snapquebec.org/passez-action/",
        //   text: "Passez à l'action",
        // },
      ],
      refresher: null,
    };
  },
  created() {
    this.refresher = setInterval(() => {
      this.$store.commit("tree/load");
    }, 60 * 1000);
  },
  computed: {
    wishesCount() {
      return this.$store.state.tree.wishes.length;
    },
  },
};
</script>
<style scoped>
.header-snap {
  width: 100vw;
  display: flex;
  flex-direction: column;
}

header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: white;
  font-family: sans-serif;
}

header > div.header-snap-title,
header > div.header-snap-logo {
  flex: 1;
}

header > div.header-snap-logo {
  display: flex;
  justify-content: flex-end;
}

header > a {
  text-align: right;
}

header h1 {
  font-weight: normal;
}
header span {
  font-size: 0.8rem;
}

header img {
  width: auto;
  height: 3rem;
}

nav ul {
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: 1rem;

  z-index: 1;
  position: absolute;
  right: 0;
}

nav ul::-webkit-scrollbar {
  display: none;
}

nav ul li {
  list-style: none;
  width: 13rem;
  min-width: 13rem;
  max-width: 13rem;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 1px white solid;
  margin-right: 1rem;

  transition: transform 500ms;

  display: block;
  margin-bottom: 0.5rem;
}

nav ul li:hover {
  transform: scale(1.05);
}

nav ul li a {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0.5rem;
  color: white;
  flex: 1;
  font-family: Arial;
  font-size: 0.8rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.header-snap-counter {
  margin: 0 1vmin;
  display: flex;
  align-items: center;
}

@media (orientation: portrait) {
  nav li {
    display: hidden;
  }

  nav li:first-child {
    display: block;
  }
}

@media (max-width: 800px) {
  .header-snap-title > span {
    display: none;
  }

  .header-snap-title > h1 {
    font-size: 5vw;
  }

  header img {
    height: 8vw;
  }
}
</style>