<template>
  <div class="end-form">
    <div class="end-form-body">
      <div class="end-msg">
        <div>Merci pour votre souhait</div>
        <div class="end-msg-link">
          <a
            class="end-msg-event"
            href="https://e-activist.com/page/82864/action/1"
            target="_blank"
          >
            <div>Comment participer à l’événement<br />Grâce à la Nature ?</div>
          </a>
        </div>
        <div class="end-msg-link">
          <a
            class="end-msg-event"
            href="https://amplify.e-activist.com/19981/galn/117398/snap-quebec-grace-a-la-nature-2021"
            target="_blank"
          >
            <div>Faire un don pour<br />la protection de la nature</div>
          </a>
        </div>
      </div>
      <div class="end-share">
        <SocialShare
          :url="shareLink"
          :title="'Parce que je veux protéger la nature québécoise, j’ai ajouté mon souhait.\nToi aussi participe à cette nouvelle tradition !'"
        />
        <clipboard-field :url="shareLink" />
      </div>
    </div>
  </div>
</template>
<script>
import ClipboardField from "../../../components/ClipboardField.vue";
import SocialShare from "../../../components/SocialShare.vue";

export default {
  name: "EndForm",
  inject: ["mitt"],
  components: {
    SocialShare,
    ClipboardField,
  },
  computed: {
    submittedId() {
      return this.$store.state.form.id;
    },
    shareLink() {
      return process.env.VUE_APP_BASE_URL + "/#/" + this.$store.state.form.id;
    },
  },
  methods: {
    submit() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
<style scoped>
.end-form {
  width: 100%;
  font-family: monospace, sans-serif;
  display: flex;
  align-items: center;
  padding: 1vmin;
}

.end-form-body,
.end-msg {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.end-form-body,
.end-msg {
  flex: 1;
}

.end-msg,
.end-submit {
  justify-content: center;
  align-items: center;
}

.end-share {
  display: flex;
  align-items: center;
}

.end-msg {
  text-align: center;
  justify-content: space-evenly;
  font-size: 2vmin;
}

.end-msg-event {
  text-decoration: none;
  color: black;
}

.end-msg-event div {
  background-color: white;
  padding: 1vmin;
  border: solid 1px #0002;

  transition: box-shadow 300ms;
}

.end-msg-event div:hover {
  box-shadow: 0 3px 5px #0002;
}
</style>