import { service } from '../../tree/service/TreeService';

export default {
  namespaced: true,
  state: {
    acceptCGU: false,
    id: null,
    lastname: null,
    firstname: null,
    email: null,
    message: null,
    commercialOptin: null,
    limitAccess: false,
  },
  mutations: {
    acceptCGU(state) {
      state.acceptCGU = true;
    },
    name(state, name) {
      state.lastname = name.lastname;
      state.firstname = name.firstname;
    },
    message(state, message) {
      state.message = message;
    },
    async email(state, info) {
      let wishes = await service.getWishesByEmail(info.email);
      if (wishes.length < 3) {
        let wish = await service.postWish({
          lastname: state.lastname,
          firstname: state.firstname,
          message: state.message,
          email: info.email,
          commercialOptin: info.commercialOptin,
        });

        state.id = wish.id;
        state.email = wish.email;
        state.commercialOptin = wish.commercialOptin;
      } else {
        state.limitAccess = true;
      }
    },
    finish(state) {
      state.acceptCGU = false;
      state.id = null;
      state.lastname = null;
      state.firstname = null;
      state.email = null;
      state.message = null;
      state.commercialOptin = null;
      state.limitAccess = false;
    }
  },
};