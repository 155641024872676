import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyA9_pGCahLyFSlVi-9h1eeVSomcD-90Jw0",
    authDomain: "snap-quebec-arbre.firebaseapp.com",
    projectId: "snap-quebec-arbre",
    storageBucket: "snap-quebec-arbre.appspot.com",
    messagingSenderId: "813728713665",
    appId: "1:813728713665:web:b12e413fd613bf7735bd40",
    measurementId: "G-B76FFJ4N1E"
};

export const collection = firebase
    .initializeApp(firebaseConfig)
    .firestore().collection(process.env.VUE_APP_BUILD_ENVIRONMENT ?? 'dev')
