<template>
  <div
    v-bind:class="'anim-' + anim"
    v-bind:style="{
      left: x + '%',
      top: y + '%',
      height: 5 + (nbr * 2) / tot + '%',
    }"
  >
    <img :src="'./imgs/img_12.webp'" />
  </div>
</template>
<script>
export default {
  name: "WishPaper",
  props: ["nbr", "tot"],
  data() {
    const r = 30;

    let xt = Math.random();
    let max = Math.sqrt(1 - xt * xt);
    let yt = Math.random() * max;

    let x = r + (Math.random() < 0.5 ? -1 : 1) * xt * r;
    let y = r + (Math.random() < 0.5 ? -1 : 0.3) * yt * r;

    return {
      x: x + 18.5,
      y: y + 18,
      anim: Math.floor(Math.random() * 3) + 1,
    };
  },
};
</script>
<style scoped>
@keyframes wish-wind {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(-2deg);
  }
  40% {
    transform: rotate(10deg);
  }
  80% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

div {
  position: absolute;
  width: auto;
  height: 6%;
  z-index: 1;

  transition: transform 400ms;
}

div::before {
  content: "Découvrir un souhait";

  position: absolute;
  padding: 0.33vmin;
  background-color: rgb(247, 242, 231);
  border-radius: 0.5vmin;
  left: 50%;
  font-size: 1vmin;
  box-sizing: border-box;
  text-align: center;

  transition: transform 300ms;
  opacity: 0;
  transform: translate3d(-50%, 0, 0);
}

div:hover::before {
  opacity: 1;
  transform: translate3d(-50%, -120%, 0);
  z-index: 999;
}

div img {
  transform-origin: top;
  animation-iteration-count: infinite;
  cursor: pointer;

  will-change: transform;

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;

  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;

  height: 100%;
  width: auto;

  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  transition: transform 400ms;

  animation-name: wish-wind;
  animation-duration: 4s;
}

div:hover {
  transform: scale(1.3) translate3d(0, 13%, 0);
  -ms-transform: scale(1.3) translate3d(0, 13%, 0);
  -webkit-transform: scale(1.3) translate3d(0, 13%, 0);
  -moz-transform: scale(1.3) translate3d(0, 13%, 0);
  -o-transform: scale(1.3) translate3d(0, 13%, 0);
}

div.anim-2 img {
  animation-delay: 250ms;
}

div.anim-3 img {
  animation-delay: 500ms;
}
</style>