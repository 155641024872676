<template>
  <div class="dialog" v-on:click.self="close">
    <slot />
  </div>
</template>
<script>
export default {
  name: "Dialog",
  inject: ["mitt"],
  props: ["show"],
  methods: {
    close() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
<style>
div.dialog {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #0007;
  z-index: 3;
}
</style>
