<template>
  <div class="info-form">
    <div class="info-inputs">
      <div class="info-form-email">
        <label for="form-email">Votre courriel :</label>
        <input id="form-email" type="email" v-model="email" />
      </div>
      <div class="info-form-optin">
        <input id="form-optin" type="checkbox" v-model="commercialOptin" />
        <label for="form-optin">
          J’accepte de recevoir des informations sur les projets de la SNAP
          Québec.
        </label>
      </div>
      <div class="info-form-cgu">
        <input id="form-cgu" type="checkbox" v-model="acceptCGU" />
        <label for="form-cgu"> J'accepte les </label>
        <a href="/politique_confidentialite.pdf" target="_blank">
          conditions générales d'utilisation
        </a>
      </div>
    </div>
    <SolidButton
      :icon="'chevron-right'"
      :disabled="!validation"
      :onclick="submit"
    />
  </div>
</template>
<script>
import SolidButton from "./SolidButton.vue";

export default {
  name: "InfoForm",
  components: {
    SolidButton,
  },
  data() {
    return {
      email: "",
      commercialOptin: false,
      acceptCGU: false,
    };
  },
  computed: {
    getId() {
      return this.$store.state.form.id;
    },
    validation() {
      const re = new RegExp(
        "^\\w+([-+.']\\w+)*@\\w+([-.]\\w+)*.\\w+([-.]\\w+)*$"
      );

      let emailValidation =
        this.email.trim().length && re.test(this.email.trim());

      return emailValidation && this.acceptCGU;
    },
  },
  methods: {
    submit() {
      this.$store.commit("form/email", {
        email: this.email,
        commercialOptin: this.commercialOptin,
      });
    },
  },
  watch: {
    getId(current, previous) {
      if (current != previous && typeof current == "string") {
        this.$store.commit("tree/load");
      }
    },
  },
};
</script>
<style scoped>
label,
a {
  font-size: 1.5vmin;
  font-family: monospace, sans-serif;
}

@media (min-width: 70rem) {
  label,
  a {
    font-size: 1.4rem;
  }

  #form-optin,
  #form-cgu {
    width: 1.4rem;
    height: 1.4rem;
  }
}

.info-form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.info-inputs {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.info-form-email {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.info-form-email label {
  font-size: 3vmin;
}

.info-form-email input {
  background-color: transparent;
  border: 0;
  border-bottom: 1px grey solid;
  font-family: "Satisfy";
  font-size: 4vmin;
  width: 90%;
  max-height: 40%;
  color: #20225a;
  outline: none;
  padding: 0 1vmin;
}

#form-optin,
#form-cgu {
  width: 1.5vmin;
  height: 1.5vmin;
}
</style>