<template>
  <div id="name-form">
    <div id="name-form-inputs">
      <div>
        <label for="firstname"> Saisissez votre prénom : </label>
        <input name="firstname" v-model="firstname" />
      </div>
      <div>
        <label for="lastname">
          Et votre nom
          <span> (Seule votre initiale apparaitra) </span>
          :
        </label>
        <input name="lastname" v-model="lastname" />
      </div>
    </div>
    <SolidButton
      :icon="'chevron-right'"
      :disabled="!validation"
      :onclick="submit"
    />
  </div>
</template>
<script>
import SolidButton from "./SolidButton.vue";

export default {
  name: "NameForm",
  components: {
    SolidButton,
  },
  data() {
    return {
      lastname: "",
      firstname: "",
    };
  },
  computed: {
    validation() {
      return this.lastname.trim().length && this.firstname.trim().length;
    },
  },
  methods: {
    submit() {
      this.$store.commit("form/name", {
        lastname: this.lastname,
        firstname: this.firstname,
      });
    },
  },
};
</script>
<style scoped>
div#name-form {
  height: 100%;

  flex: 1;
  display: flex;
  align-items: center;
}

div#name-form-inputs {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

div#name-form-inputs > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

div > label {
  font-family: monospace, sans-serif;
  font-size: 3vmin;
  display: block;
}

div > input {
  flex: 1;
  background-color: transparent;
  border: 0;
  border-bottom: 1px grey solid;
  font-family: "Satisfy";
  font-size: 4vmin;
  width: 90%;
  max-height: 50%;
  color: #20225a;
  outline: none;
  padding: 0 1vmin;
}

div span {
  font-family: monospace, sans-serif;
  font-size: 2vmin;
  color: grey;
}

div > input:focus,
div > input:active {
  border: 0px solid;
  border-bottom: 2px grey solid;
}
</style>