<template>
  <div class="message-container">
    <div class="message-form-body" v-on:click="giveFocus">
      <textarea
        class="message-form-textarea"
        ref="messageInput"
        v-model="message"
        v-on:input="onMessage"
        placeholder="Je souhaite..."
        maxlength="200"
      ></textarea>

      <!-- <div class="placeholder" v-show="!validation">Je souhaite...</div>
      <p
        ref="message"
        :style="{
          'font-size':
            'calc(' + dynamicFontSize + 'vw + ' + dynamicFontSize + 'vh)',
        }"
      >
        {{ message }}
      </p>
      <input type="text" v-model="message" /> -->
    </div>
    <div class="message-form-footer">
      <div class="message-form-length">
        {{ message ? message.length : "0" }}/200
      </div>
      <div class="message-form-moderation">
        Votre souhait fera l’objet d’une modération.
      </div>
      <div>
        <p>{{ authorName }}</p>
      </div>
      <SolidButton
        :icon="'chevron-right'"
        :disabled="!validation"
        :onclick="submit"
        :max="200"
      />
    </div>
  </div>
</template>
<script>
import SolidButton from "./SolidButton.vue";

export default {
  name: "MessageForm",
  components: {
    SolidButton,
  },
  data() {
    return {
      message: "",
    };
  },
  computed: {
    validation() {
      return !!this.message.trim().length;
    },
    getMessage() {
      return this.message.slice(0, 200);
    },
    authorName() {
      if (this.$store.state.form.firstname && this.$store.state.form.lastname) {
        return (
          this.$store.state.form.firstname +
          " " +
          this.$store.state.form.lastname.toUpperCase()[0] +
          "."
        );
      }
      return "";
    },
  },
  methods: {
    dynamicFontSize() {
      let len = this.message.length ?? 200;
      let ave = len / 200;

      return 1.05 * (1 - ave) + 1;
    },
    giveFocus() {
      this.$refs.messageInput.focus();
    },
    onMessage() {
      let messageInput = this.$refs.messageInput;
      messageInput.style = `
          height: 0px;
          font-size: calc(${this.dynamicFontSize()}vw + ${this.dynamicFontSize()}vh)
        `;
      let height = messageInput.scrollHeight;
      messageInput.style = `
          height: ${height}px;
          font-size: calc(${this.dynamicFontSize()}vw + ${this.dynamicFontSize()}vh)
        `;
    },
    submit() {
      if (this.message.length > 0) {
        this.$store.commit("form/message", this.message);
      }
    },
  },
};
</script>
<style scoped>
textarea,
p {
  font-family: Satisfy;
}

.message-container,
.message-form-body,
.message-form-footer,
.placeholder {
  display: flex;
}

.message-container {
  width: 100%;
  flex-direction: column;
}

.message-form-body,
.message-form-moderation {
  flex: 1;
}

.message-form-body {
  justify-content: center;
  align-items: center;
}

.message-form-textarea {
  width: 100%;
  font-size: 4vmin;
  text-align: center;
  outline: none;
  background-color: transparent;
  border: none;
  resize: none;
}

.message-form-textarea::-webkit-scrollbar {
  display: none;
}

.message-form-moderation {
  font-family: monospace, sans-serif;
  font-size: 1.5vmin;
  opacity: 0.5;
  text-align: center;
}

.message-form-footer {
  align-items: center;
}

.message-form-footer p {
  font-size: 3vmin;
  margin: 0 2vmin;
}

.message-form-length {
  font-family: monospace, sans-serif;
}
</style>